
<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
        <h5>ADD NEW CATEGORY</h5>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>
    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="p-6">
        <input type="file" class="hidden" ref="uploadImgInput" @change="updateCurrImg" accept="image/*">
        <!-- Product Image -->
        <template v-if="imageHolder">

          <!-- Image Container -->
          <div class="img-container w-64 mx-auto flex items-center justify-center">
            <img :src="imageHolder" alt="img" class="responsive">
          </div>
          <!-- Image upload Buttons -->
          <div class="modify-img flex justify-between mt-5">
            <vs-button class="mr-4"  size="small" type="flat" @click="$refs.uploadImgInput.click()">Update Image</vs-button>
            <vs-button type="flat"  size="small" color="#999" @click="removeImage">Remove Image</vs-button>
          </div>
        </template>

        <!-- NAME -->
        <vs-input label="Name *" placeholder="category name" v-model="data.name" class="mt-5 w-full" name="name" v-validate="'required'" />
        <span class="text-danger text-sm" v-show="errors.has('name')">{{ errors.first('name') }}</span>

        <!-- NAME -->
        <vs-input label="Slug *" placeholder="slug" v-model="data.slug" class="mt-5 w-full" name="slug" v-validate="{ required: true, regex: /^[a-z0-9-_]+$/}" />
        <span class="text-danger text-sm" v-show="errors.has('slug')">{{ errors.first('slug') }}</span>
        <!-- Upload -->
        <!-- <vs-upload text="Upload Image" class="img-upload" ref="fileUpload" /> -->
        <div class="upload-img mt-5" v-if="!imageHolder">
          <p class="text-sm">
            Dimension should be <code>500px</code> x <code>500px</code><br> Maximum Size <code> 500kb</code>
          </p>
          <vs-button size="small" @click="$refs.uploadImgInput.click()">Upload Image</vs-button>
        </div>
      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData">Submit</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancel</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    isSidebarActive(val) {
      if(!val) return
    }
  },
  data() {
    return {
      imageHolder: "",
      newImage: "",
      data:{
        name: "",
        slug: "",
      },
      settings: { // perfectscrollbar settings
          maxScrollbarLength: 60,
          wheelSpeed: .60,
      },
    }
  },
  watch: {
    'data.name': function (name){
      this.data.slug = name.toString().normalize('NFD').replace(/[\u0300-\u036f]/g,'').replace(/\s+/g,'-').toLowerCase().replace(/&/g,'-and-').replace(/[^a-z0-9\-]/g,'').replace(/-+/g,'-').replace(/^-*/,'').replace(/-*$/,'');
    },
  },
  computed: {
    parentCategories(){
      return this.$store.state.categoryList.categories.filter(ct => !ct.parent_id)
    },
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if(!val) {
          this.$emit('closeSidebar')
          this.resetForm()
        }
      }
    },
  },
  methods: {
    submitData() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading({color: "#444", type: "sound"})
          let formData = new FormData();
          formData.append('data', JSON.stringify(this.data));
          formData.append('image', this.newImage);
          this.$store.dispatch("categoryList/addCategory",formData)
          .then((response) => {
            this.$vs.loading.close()
            if(response.data.success){
              this.resetForm()
              this.$emit('closeSidebar')
            }
          })
        }
      })
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader()
        reader.onload = e => {
          this.imageHolder = e.target.result
        }
        this.newImage = input.target.files[0]
        reader.readAsDataURL(input.target.files[0])
      }
    },
    removeImage(){
      this.imageHolder =  ""
      this.newImage = ""
      this.$refs.uploadImgInput.value = ""
    },
    resetForm() {
      this.imageHolder =  ""
      this.newImage = ""
      this.data = {
        name: "",
        slug: "",
      }
      this.$refs.uploadImgInput.value = ""
      this.$validator.reset()
    },
  },
  components: {
    VuePerfectScrollbar,
  }
}

</script>

<style lang="scss" scoped>

.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
